
import CompTable from "@/components/comp-table.vue"

import Request from "@/module/request/request"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "审批状态",
                    render: (h: Function, params: obj) => {
                        return h(
                            "span",
                            {
                                style: {
                                    color: ["#999", "#67C23A", "#F56C6C", "#909399"][parseInt(params.row.approvalStatus)],
                                },
                            },
                            ["待审核", "已通过", "已拒绝", "取消申请"][parseInt(params.row.approvalStatus)] || "未知"
                        )
                    },
                },
                {
                    title: "订单名称",
                    key: "order.name",
                },
                {
                    title: "原价",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.order.totalPrice ? "0.00" : (params.row.order.totalPrice / 100).toFixed(2))
                    },
                },
                {
                    title: "申请优惠价格",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.discountAmount ? "0.00" : (params.row.discountAmount / 100).toFixed(2))
                    },
                },
                {
                    title: "备注",
                    key: "note",
                },
                {
                    title: "申请日期",
                    key: "createdDate",
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 200,
                    slot: "row-operate",
                },
            ],
        }
    },

    methods: {
        /**
         * 同意/拒绝申请
         */
        onChangeApply(uuid: string, status: string) {
            Request.post(
                "WEB://Discount/Handle",
                {
                    uuid: uuid,
                    approvalStatus: status,
                },
                {
                    success: "操作成功",
                }
            ).then(() => {
                // 刷新表格
                this.$refs.comp_table.refresh()
            })
        },
    },
}
